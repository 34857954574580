.video-container {
    width: 100%;
    max-width: 900px; /* Adjust the max-width as needed */
    margin: 0 auto;
  }
  
  .video-player {
    position: relative;
    background-color: #6B7280;
    cursor: pointer;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    background-image: linear-gradient(to top right, #9F7AEA, #2563EB);
  }
  
  .play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .play-icon {
    width: 64px;
    height: 64px;
    fill: #fff;
    opacity: 0.75;
    transition: opacity 0.2s;
  }
  
  .play-icon:hover {
    opacity: 1;
  }
  
  .play-label {
    color: #fff;
    margin-left: 8px;
  }
  
  .video-iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  