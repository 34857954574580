/* Sidebar links */
#sidebar-area .sidebar-anchor {
	text-decoration: none;
	font-weight: bold;
	font-family: "Fira Sans" , sans-serif;
	width: 90% ;
}


#sidebar-area .active {
	background-color:rgb(158,89,239);	
}

/* Hover links */
#sidebar-area a:hover {
	background-color:rgb(158,89,239);	
}



.navBar {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	height: 5px;
}


.burger div {
	width: 25px;
	height: 3px;
	background-color: rgb(33,41,54);
	margin: 5px;
	transition: all 0.3s ease;
	cursor: pointer;
}

/*Aside - left Hand Sidebar*/
aside {
	position: fixed;
	left: 0px;
	top: 0px;
	text-align: left;
	z-index: 2;
}


/* .containerAside {
	margin-bottom: 500%;
	display: flex;
	flex-direction: column;
	margin-top: 10%;
	color: white;
	font-weight: 500;
	letter-spacing: 1px;
	padding-bottom: 100%;
} */

.containerAside a {
	color: white;
	cursor: pointer;
	padding-bottom: 40px;
	padding-left: 15px;
	text-align: left;
	font-size: 15px;
	font-weight: 400;
}


/* Hamburger positioning */
#sidebar-area .navBar .burger {
	margin: 0.5em;
}


/* Hamburger lines */
#sidebar-area .navBar .burger .line1,
#sidebar-area .navBar .burger .line2,
#sidebar-area .navBar .burger .line3 {
	background-color:rgb(158,89,239);	
	width: 25px;
	height: 3px;
	background-color:rgb(158,89,239);	
	margin: 5px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	border-radius: 5px;
}


/* Sidebar formatting */
#sidebar-container {
	width: 200px;
	position: fixed;
	box-shadow: 3px 0px 8px 2px rgba(13, 122, 230, 0.1);
	-webkit-box-shadow: 3px 0px 8px 2px rgba(13, 122, 230, 0.1);
	border-radius: 0px 8px 8px 0px;
	color: #fbc434;
	left: 0px;
	top: 0px;
	height: 100%;
}

/* Sidebar logo container*/
#sidebar_lead_z_lab_container {
	font-size: 40px;
	text-align: left;
	text-decoration: none;
	font-weight: bold;
	font-family: "Fira Sans" ,sans-serif;
}

#sidebar_lead_z_lab_container a:hover{
	background: #33373d !important;	
}

/* Sidebar position */
#sidebar-area #sidebar-container #sidebar__logo {
	color: #fbc434;
	font-family: "Fira Sans" , sans-serif;
	font-weight: 800;
	font-size: 25px;
	margin-left: 2.5em;
}

/* Sidebar */
#sidebar-area #sidebar-container .containerAside a {
	color: white;
	font-family: "Fira Sans", sans-serif;
	font-weight: 600 !important;
	font-size: 1rem;
	-webkit-transition: 0.1s ease-in;
	transition: 0.1s ease-in;
	margin: 1em 0;
	padding: 0;
	padding: 10px 0px 10px 10px;
	border-radius: 0 10px 10px 0;
}


/* Hamburger animation */
@-webkit-keyframes navLinkFade {
	from {
		opacity: 0;
		-webkit-transform: translateX(-50px);
		transform: translateX(-50px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
}

@keyframes navLinkFade {
	from {
		opacity: 0;
		-webkit-transform: translateX(-50px);
		transform: translateX(-50px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
}

.toggle .line1 {
	-webkit-transform: rotate(-45deg) translate(-5px, 6px);
	transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
	opacity: 0;
}

.toggle .line3 {
	-webkit-transform: rotate(45deg) translate(-5px, -6px);
	transform: rotate(45deg) translate(-5px, -6px);
}


/* Tablet */
@media screen and (max-width: 1026px) {

	aside {
		left: -1000px;
	}
	.nav-active {
		position: fixed;
		left: 0;
		top: 30px;
	}
	.containerAside {
		margin-bottom: 500%;
		display: flex;
		flex-direction: column;
		margin-top: 10%;
		color: white;
		font-weight: 500;
		letter-spacing: 1px;
	}

	#sidebar-area .navBar .burger {
		z-index: 3;
		margin: 0.3rem;
	}
	#sidebar-area .sidebar {
		left: -1000px;
		overflow-y: auto;
		width: 30%;
		scrollbar-width: none;
	}
	#sidebar-area .sidebar::-webkit-scrollbar {
		display: none;
	}
	#sidebar-area .nav-active {
		position: fixed;
		left: 0;
	}
	#sidebar-area .containerAside {
		margin-top: 2rem;
		margin-bottom: 500%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-top: 10%;
		color: white;
		font-weight: 500;
		letter-spacing: 1px;
	}
}



/* Phone */
@media screen and (max-width: 768px) {

	aside {
		left: -1000px;
	}
	.nav-active {
		position: fixed;
		left: 0;
		top: 30px;
	}

	#sidebar-area .navBar .burger {
		z-index: 3;
	}
	#sidebar-area .sidebar {
		left: -1000px;
		overflow-y: auto;
		width: 80%;
		scrollbar-width: none;
	}
	#sidebar-area .sidebar::-webkit-scrollbar {
		display: none;
	}
	#sidebar-area .nav-active {
		position: fixed;
		left: 0;
	}
}