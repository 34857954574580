.dashboard {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 50px;
}

.dashboard .recent-executions{
    margin-top: 50px;
}


/* Phone */
@media screen and (min-width: 1026px) {

	.dashboard {
        margin-left: 210px;
        margin-top: 70px ;
	}

}


