.sign-up-scrollbar::-webkit-scrollbar {
    /* width: 8px; */
    display: none;
}

.sign-up-scrollbar::-webkit-scrollbar-track {
    /* background-color: #f1f1f1;
    border-radius: 4px; */
    display: none;
}

.sign-up-scrollbar::-webkit-scrollbar-thumb {
    /* background-color: #888;
    border-radius: 4px;   */
    display: none;
}

.sign-up-scrollbar::-webkit-scrollbar-thumb:hover {
    /* background-color: #555; */
    display: none;
}