.job-detail {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 50px;
}


.react-select-container {
    width: 100%;
}
  
.react-select__control {
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    padding: 0.5rem;
}

.react-select__single-value {
    color: #4b5563;
}

.react-select__option--is-selected {
    background-color: #d1d5db;
}
  

/* Phone */
@media screen and (min-width: 1026px) {

	.job-detail {
        margin-left: 210px;
        margin-top: 30px ;
	}

}


