.execution-detail {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 50px;
}

.react-select-container {
    width: 100%;
}

.formatted-body {
    white-space: pre-wrap;
  }

/* ExecutionDetail.css */
.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .modal-content button {
    margin-top: 10px;
  }
  

  .modal-content-scrollbar::-webkit-scrollbar {
    width: 8px; 
    
}

.modal-content-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 4px; 
    
}

.modal-content-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;   
    
}

.modal-content-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
    
}

  

/* Phone */
@media screen and (min-width: 1026px) {

    .execution-detail {
        margin-left: 200px;
        margin-top: 30px ;
	}

}


